import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import { ICraigMessage } from "./types"

class CraigApi {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async getLatestMessages(environment: "live" | "dev" = "dev", user?: string) {
    const sp = new URLSearchParams()
    if (user) sp.set("user", user)
    sp.set("assistant", environment)

    return this.api.get("/craig/messages?"+sp.toString()).then(res => res.data as {
      messages?: ICraigMessage[]
    })
  }

  async sendMessage(message: string, url: string | null, environment: "live" | "dev" = "dev") {
    return this.api.post("/craig/message", { message, url, assistant: environment }).then(res => res.data as {
      userMessage: ICraigMessage,
      craigMessage: ICraigMessage,
    })
  }
}

const craigApi = new CraigApi()

export default craigApi
