import { FC } from "react"
import { Route, Routes } from "react-router"
import AppLayout from "layouts/App"
import ProfilePage from "pages/app/Profile"
import UserChatHistoryPage from "pages/app/UserChatHistory"
import CraigUnmatchedFallbacksPage from "pages/app/Fallbacks"
import CraigDialogConfigPage from "pages/app/Dialogs"

const PrivateRouter: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<AppLayout />}>
        <Route index element={<ProfilePage />} />
        <Route path="dialogs" element={<CraigDialogConfigPage />} />
        <Route path="unmatched" element={<CraigUnmatchedFallbacksPage />} />
        <Route path="history" element={<UserChatHistoryPage />} />
      </Route>
    </Routes>
  )
}

export default PrivateRouter
