import { Logo } from "assets"
import { ChatIcon, FilterIcon, LogoutIcon, ProfileIcon, RatingStarIcon } from "assets/icons"
import Scrollbar from "components/Scrollbar"
import Tooltip from "components/Tooltip"
import firebase from "config/firebase"
import CraigProvider from "contexts/Craig/CraigProvider"
import CraigV2ConfigProvider from "contexts/CraigV2Config"
import { signOut } from "firebase/auth"
import { FC, Fragment, FunctionComponent, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router"

interface props {
  routes: {
    path: string
    onClick?: () => void
    name: string
    icon: FunctionComponent<React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
}>
  }[]
}

const routes = [
  {
    path: "/",
    name: "Account",
    icon: ProfileIcon,
  },
  {
    path: "/unmatched",
    name: "Unmatched Fallback Config",
    icon: FilterIcon,
  },
  {
    path: "/dialogs",
    name: "Dialog Config",
    icon: ChatIcon,
  },
  {
    path: "/history",
    name: "User Chat History",
    icon: RatingStarIcon,
  },
] as props["routes"]

const AppLayout: FC = () => {
  const [signingOut, setSigningOut] = useState(false)
  const navigate = useNavigate()
  const pathname = useLocation().pathname

  const onLogout = () => {
    if (signingOut) return
    setSigningOut(true)
    signOut(firebase.auth).finally(() => setSigningOut(false))
  }

  return (
    <CraigV2ConfigProvider>
      <CraigProvider>
        <div className="w-full h-full bg-white flex p-2">
          <div className="h-full rounded-3xl bg-primary-blue flex-cool gap-2.5 items-center py-4">
            <Logo className="w-[2.75rem] h-[2.75rem] p-1.5 rounded-2xl bg-primary-purple fill-white shrink-0" />
            <div className="grow flex-cool gap-4 w-full px-4 items-center overflow-y-auto relative mt-4">
              {routes.map((route, i) => (
                <Fragment key={route.path}>
                  {!!i && (<div className="w-8 h-px bg-border-divide" />)}
                  <Tooltip position="right" text={route.name} className="bg-slate-800 z-20">
                    <route.icon
                      onClick={route.onClick || (() => navigate(route.path))}
                      className={[
                        "w-[2.75rem] h-[2.75rem] p-2.5 rounded-full hover:bg-[rgba(255,255,255,0.12)] cursor-pointer transition-color",
                        (route.path === "/" ? pathname === "/" : pathname.startsWith(route.path)) && "bg-[rgba(255,255,255,0.12)]",
                      ].asClass}
                    />
                  </Tooltip>
                </Fragment>
              ))}
              <div className="w-8 h-px bg-border-divide" />
              <Scrollbar />
            </div>
            <Tooltip position="right" text="Log Out" className="bg-slate-800 z-20">
              <LogoutIcon
                onClick={onLogout}
                className="w-[2.75rem] h-[2.75rem] p-2.5 rounded-full hover:bg-[rgba(255,255,255,0.12)] cursor-pointer transition-color"
              />
            </Tooltip>
          </div>
          <div className="h-full grow w-full overflow-hidden">
            <Outlet />
          </div>
        </div>
      </CraigProvider>
    </CraigV2ConfigProvider>
  )
}

export default AppLayout
