import { Auth } from "pages/auth"
import { FC } from "react"
import { Navigate, Route, Routes } from "react-router"

const AuthRouter: FC = () => {
  return (
    <Routes>
      <Route index element={<Auth />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default AuthRouter
