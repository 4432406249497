import { FC, useEffect, useState } from "react"
import Loader from "components/loaders/Loader"
import adminApi from "api/admin"
import { ICraigMessage } from "api/types"
import CraigBubble from "../Dialogs/components/Chat/components/CraigBubble"
import UserBubble from "../Dialogs/components/Chat/components/UserBubble"
import craigApi from "api/craig"

const UserChatHistoryPage: FC = () => {
  const [_email, _setEmail] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [id, setId] = useState<string | null>()
  const [environment, setEnvironment] = useState<"live" | "dev">("dev")
  const [messages, setMessages] = useState<ICraigMessage[]>([])

  useEffect(() => {
    if (!email) return
    setId(undefined)
    adminApi.getUserByEmail(email).then(res => {
      if (!res) return setId(null)
      setId(res.id)
    })
  }, [email])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEmail(_email)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [_email])

  useEffect(() => {
    if (id === undefined || id === null) return setMessages([])
    craigApi.getLatestMessages(environment, id).then(res => {
      setMessages(res.messages || [])
    })
  }, [id, environment])

  return (
    <div className="w-full h-full relative overflow-hidden flex flex-col">
      <div className="flex items-center p-5 border-b border-b-slate-100 gap-4">
        <span className="text-xl font-semibold text-primary-blue">
          User Chat History
        </span>
        <input
          type="search"
          placeholder="Search Email"
          value={_email}
          onChange={e => _setEmail(e.target.value)}
          className="input p-2"
        />
        <div className="grow" />
        <select
          value={environment}
          onChange={e => setEnvironment(e.currentTarget.value as any)}
          className="text-sm font-medium text-slate-950 bg-transparent cursor-pointer"
        >
          <option value="live">Live</option>
          <option value="dev">Dev</option>
        </select>
      </div>
      <div className="grow flex overflow-y-auto">
        <div className="flex flex-col items-start gap-4 py-3 px-5 grow">
          {
            id === undefined ? (
              email ? (
                <div className="w-full h-full flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <span className="text-lg text-slate-900">Search for a user by email</span>
                </div>
              )
            ) : id === null ? (
              <div className="w-full h-full flex items-center justify-center">
                <span className="text-lg text-slate-900">User not found</span>
              </div>
            ) : (
              messages?.map(message => (
                message.user ? <UserBubble key={message.id} message={message} /> : <CraigBubble key={message.id} message={message} />
              ))
            )
          }
        </div>
      </div>
    </div>
  )
}

export default UserChatHistoryPage
