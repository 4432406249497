import { ICraigV2Dialog, ICraigV2DialogCraigResponse, ICraigV2TopLevelDialog } from "api/types"
import { TrashIcon, ChevronIcon } from "assets/icons"
import { Dispatch, FC, SetStateAction, useCallback, useState } from "react"

interface CraigResponseProps {
    response: ICraigV2DialogCraigResponse;
    setResponse: Dispatch<SetStateAction<ICraigV2DialogCraigResponse>>;
    onDelete: () => void;
}

const CraigResponse: FC<CraigResponseProps> = ({ response, setResponse, onDelete }) => {
  const onSelectType = useCallback((type: "none" | "file" | "url" | "seen") => {
    setResponse((old: any) => ({ ...old, payload: type === "none" ? undefined : { type } }))
  }, [setResponse])

  return (
    <div className="flex flex-col gap-1">
      <span className="text-lg font-medium">Response</span>
      <div className="flex items-center gap-4">
        <input
          className="grow"
          defaultValue={response.content}
          onBlur={e => setResponse((old: any) => ({ ...old, content: e.target.value }))}
        />
        <TrashIcon
          onClick={onDelete}
          className="w-8 h-8 cursor-pointer hover:bg-slate-200 p-1 rounded-full"
        />
      </div>
      <div className="flex items-center gap-4">
        <span className="text-lg font-medium">Payload</span>
        <select
          value={response.payload?.type || "none"}
          onChange={e => onSelectType(e.target.value as any)}
          className="w-40"
        >
          <option value="none">None</option>
          <option value="file">File</option>
          <option value="url">URL</option>
          <option value="seen">Seen</option>
        </select>
      </div>
      {
        response.payload?.type === "file" && (
          <div className="flex items-center gap-4">
            <input
              className="grow"
              placeholder="File Name"
              defaultValue={response.payload.name}
              onBlur={e => setResponse((old: any) => ({ ...old, payload: { ...old.payload, name: e.target.value } }))}
            />
            <input
              className="grow"
              placeholder="File URL"
              defaultValue={response.payload.url}
              onBlur={e => setResponse((old: any) => ({ ...old, payload: { ...old.payload, url: e.target.value } }))}
            />
            <select
              value={response.payload?.subType || ""}
              placeholder="File Type"
              onChange={e => setResponse((old: any) => ({ ...old, payload: { ...old.payload, subType: e.target.value || undefined } }))}
              className="w-40"
            >
              <option value="">Other</option>
              <option value="image">Image</option>
              <option value="video">Video</option>
              <option value="audio">Audio</option>
              <option value="document">Document</option>
            </select>

          </div>
        )
      }
      {
        response.payload?.type === "url" && (
          <div className="flex items-center gap-4">
            <input
              className="grow"
              placeholder="URL Name"
              defaultValue={response.payload.name}
              onBlur={e => setResponse((old: any) => ({ ...old, payload: { ...old.payload, name: e.target.value } }))}
            />
            <input
              className="grow"
              placeholder="URL"
              defaultValue={response.payload.url}
              onBlur={e => setResponse((old: any) => ({ ...old, payload: { ...old.payload, url: e.target.value } }))}
            />
          </div>
        )
      }
    </div>
  )
}

interface TopLevelProps {
    dialog: ICraigV2TopLevelDialog;
    setDialog: Dispatch<SetStateAction<ICraigV2TopLevelDialog>>;
    onDelete: () => void;
    open: boolean;
    toggleOpen: () => void;
    notTopLevel?: false;
}

interface NotTopLevelProps {
    dialog: ICraigV2Dialog;
    setDialog: Dispatch<SetStateAction<ICraigV2Dialog>>;
    onDelete: () => void;
    open: boolean;
    toggleOpen: () => void;
    notTopLevel: true;
}

const Dialog: FC<NotTopLevelProps | TopLevelProps> = ({ dialog, setDialog, onDelete, open, toggleOpen, notTopLevel }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const handleDelete = () => {
    const confirm = window.confirm("Are you sure you want to delete this dialog?")
    if (!confirm) return
    onDelete()
  }

  const nestedToggleOpen = useCallback((i: number) => {
    setOpenIndex(old => old === i ? null : i)
  }, [])

  const addDialog = useCallback(() => {
    setDialog((old: any) => old && ({
      ...old,
      followUps: [...old.followUps, {
        name: "New Followup Dialog",
        userMessageExamples: [],
        craigResponseExamples: [],
        followUps: [],
      }],
    }))
  }, [setDialog])

  const deleteDialog = useCallback((index: number) => {
    setDialog((old: any) => old && ({
      ...old,
      followUps: old.followUps.filter((_: any, i: number) => i !== index),
    }))
  }, [setDialog])

  const nestedSetDialog = useCallback((index: number) => (update: ICraigV2Dialog | ((old: ICraigV2Dialog) => ICraigV2Dialog)) => {
    setDialog((old: any) => old && ({
      ...old,
      followUps: old.followUps.map((dialog: ICraigV2Dialog, i: number) => {
        if (i === index) {
          return typeof update === "function" ? update(dialog) : update
        }

        return dialog
      }),
    }))
  }, [setDialog])

  const setUserMessage = useCallback((index: number) => (update: string | ((old: string) => string)) => {
    setDialog((old: any) => old && ({
      ...old,
      userMessageExamples: old.userMessageExamples.map((message: string, i: number) => {
        if (i === index) {
          return typeof update === "function" ? update(message) : update
        }

        return message
      }),
    }))
  }, [setDialog])

  const addUserMessage = useCallback(() => {
    setDialog((old: any) => old && ({
      ...old,
      userMessageExamples: [...old.userMessageExamples, ""],
    }))
  }, [setDialog])

  const deleteUserMessage = useCallback((index: number) => {
    setDialog((old: any) => old && ({
      ...old,
      userMessageExamples: old.userMessageExamples.filter((_: any, i: number) => i !== index),
    }))
  }, [setDialog])

  const setCraigResponse = useCallback((index: number) => (update: ICraigV2DialogCraigResponse
            | ((old: ICraigV2DialogCraigResponse) => ICraigV2DialogCraigResponse)) => {
    setDialog((old: any) => old && ({
      ...old,
      craigResponseExamples: old.craigResponseExamples.map((response: ICraigV2DialogCraigResponse, i: number) => {
        if (i === index) {
          return typeof update === "function" ? update(response) : update
        }

        return response
      }),
    }))
  }, [setDialog])

  const addCraigResponse = useCallback(() => {
    setDialog((old: any) => old && ({
      ...old,
      craigResponseExamples: [...old.craigResponseExamples, {}],
    }))
  }, [setDialog])

  const deleteCraigResponse = useCallback((index: number) => {
    setDialog((old: any) => old && ({
      ...old,
      craigResponseExamples: old.craigResponseExamples.filter((_: any, i: number) => i !== index),
    }))
  }, [setDialog])

  return (
    <div className="w-full flex flex-col font-medium border border-slate-100 bg-slate-50">
      <div
        className={[
          "flex items-center p-5 border border-slate-100",
          open && "bg-slate-200",
        ].asClass}
      >
        <input
          onClick={e => e.stopPropagation()}
          className="grow text-left"
          defaultValue={dialog.name}
          onBlur={e => setDialog((old: any) => old && ({ ...old, name: e.target.value }))}
        />
        <div className="flex gap-2">
          <TrashIcon
            onClick={handleDelete}
            className="w-8 h-8 ml-2 cursor-pointer hover:bg-slate-200 p-1 rounded-full"
          />
          <ChevronIcon
            onClick={() => toggleOpen()}
            className="w-8 h-8 cursor-pointer hover:bg-slate-200 p-1 rounded-full"
            style={{ transform: open ? "rotate(0deg)" : "rotate(180deg)" }}
          />
        </div>
      </div>
      {
        open && (
          <div className="w-full flex flex-col gap-2.5 p-5 border-t border-t-slate-100">
            {!notTopLevel && (
              <>
                <div className="flex items-center gap-4">
                  <span className="text-lg font-semibold">Contexts</span>
                </div>
                <div className="flex flex-col gap-2.5 border-b border-b-slate-200 pb-5">
                  <div className="flex items-center gap-2.5">
                    <span className="text-lg font-medium">Location</span>
                    <input
                      className="grow"
                      defaultValue={dialog.contexts.location}
                      onBlur={e => setDialog((old: any) => old
                         && ({ ...old, contexts: { ...old.contexts, location: e.target.value || "any" } }))}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center gap-4">
              <span className="text-lg font-semibold">Example User Messages</span>
              <button className="btn btn-secondary" onClick={addUserMessage}>Add Example</button>
            </div>
            <div className="flex flex-col gap-2.5 border-b border-b-slate-200 pb-5">
              {dialog.userMessageExamples.length
                ? dialog.userMessageExamples.map((message, i) => (
                  <div key={message + i} className="flex items-center gap-4">
                    <span>
                      <span className="text-lg font-medium">#{i + 1}</span>
                    </span>
                    <input
                      className="grow"
                      defaultValue={message}
                      onBlur={e => setUserMessage(i)(e.target.value)}
                    />
                    <button
                      onClick={() => deleteUserMessage(i)}
                      className="btn btn-secondary"
                    >
                      Delete
                    </button>
                  </div>
                )) : (
                  <span className="text-lg text-text-secondary">No User Messages Added</span>
                )}
            </div>
            <div className="flex items-center gap-4">
              <span className="text-lg font-semibold">Example Craig Responses</span>
              <button className="btn btn-secondary" onClick={addCraigResponse}>Add Example</button>
            </div>
            <div className="flex flex-col gap-2.5 border-b border-b-slate-200 pb-5">
              {dialog.craigResponseExamples.length
                ? dialog.craigResponseExamples.map((response, i) => (
                  <CraigResponse
                    key={response.content || "" + i}
                    response={response}
                    setResponse={setCraigResponse(i)}
                    onDelete={() => deleteCraigResponse(i)}
                  />
                )) : (
                  <span className="text-lg text-text-secondary">No Craig Responses Added</span>
                )}
            </div>
            <div className="flex items-center gap-4 border-b border-b-slate-200 pb-5">
              <span className="text-lg font-semibold">Follow Ups</span>
              <button className="btn btn-secondary" onClick={addDialog}>Add Followup</button>
            </div>
            <div className="flex flex-col gap-2.5">
              {dialog.followUps.map((followUp, i) => (
                <Dialog
                  key={followUp.name + i}
                  dialog={followUp}
                  setDialog={nestedSetDialog(i)}
                  onDelete={() => deleteDialog(i)}
                  open={openIndex === i}
                  toggleOpen={() => nestedToggleOpen(i)}
                  notTopLevel
                />
              ))}
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Dialog
