import { useState, FC, PropsWithChildren, useEffect, useCallback } from "react"
import CraigContext from "./craigContext"
import { ICraigMessage } from "api/types"
import craigApi from "api/craig"

const CraigProvider: FC<PropsWithChildren> = ({ children }) => {
  const [environment, setEnvironment] = useState<"live" | "dev">("dev")
  const [messages, setMessages] = useState<ICraigMessage[]>([])
  const [sending, setSending] = useState(false)
  const [location, setLocation] = useState<string>("")

  const sendMessage = useCallback(async(message: string, onSuccess?: () => void) => {
    if (sending) return
    if (!messages) return
    setSending(true)
    const old = messages
    const pathWithoutSearch = location || window.location.pathname
    setMessages([...old, { message, user: true, id: Math.random().toString(), createdAt: new Date().toISOString() }])
    craigApi.sendMessage(message, pathWithoutSearch, environment).then(res => {
      setMessages(old => [...(old || []), res.craigMessage])
      setSending(false)
      if (onSuccess) onSuccess()
    }).catch(() => {
      setMessages(old)
      setSending(false)
    })
  }, [environment, messages, sending, location])

  useEffect(() => {
    craigApi.getLatestMessages(environment).then(res => {
      setMessages(res.messages || [])
    })
  }, [environment])

  return (
    <CraigContext.Provider value={{ messages, sending, sendMessage, setEnvironment, environment, location, setLocation }}>
      {children}
    </CraigContext.Provider>
  )
}

export default CraigProvider
