import { User, signOut } from "firebase/auth"
import { useState, useEffect, FC, PropsWithChildren } from "react"
import firebase from "../../config/firebase"
import AuthContext from "./authContext"
import { decodeToken } from "react-jwt"
import { handleError } from "helpers/errors"

export const adminIds = ["wc9A904uNDTGxlSRXytkb2Fihz92", "kowZRHgznvMKuyJWFemppnaQKUB3"]

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User & {xpId?: string} | null>()

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        // @ts-ignore
        const decodedToken = decodeToken(user.accessToken) as {xpId?: string; sub: string}
        if (!adminIds.includes(decodedToken.sub)) {
          signOut(firebase.auth).then(() => {
            handleError("Unauthorized")
          })
        } else {
          setUser({ ...user, xpId: decodedToken?.xpId })
          localStorage.setItem("beenInApp", "true")
        }
      } else {
        setUser(old => {
          if (old) {
            window.location.href = "/auth"
          }

          return null
        })
      }
    })

    return unsubscribe
  }, [])

  return (
    <AuthContext.Provider value={user}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
