import { EnvelopeIcon, LockIcon } from "assets/icons"
import useAwaitableModal from "hooks/useAwaitableModal"
import { FC } from "react"
import EditPassword from "./modals/EditPassword"
import useAuthContext from "contexts/Auth/useAuthContext"

const ProfilePage: FC = () => {
  const auth = useAuthContext()!
  const [editPassword, EditPasswordModal] = useAwaitableModal(EditPassword, {} as any)

  const onEditPassword = () => {
    editPassword({})
      .then(() => {})
      .catch(() => {})
  }

  return (
    <div className="w-full h-full">
      {EditPasswordModal}
      <div className="pt-8 px-8 gap-8 flex-cool h-full">
        <div className="gap-8 flex items-center mobile:flex-col mobile:gap-4">
          <span className="font-semibold text-3xl text-slate-900">Admin Profile</span>
        </div>
        <div
          className="flex-cool min-h-min
          grow
        bg-white rounded-3xl divide-y
        divide-y-slate-200 overflow-hidden overflow-y-auto"
        >
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <EnvelopeIcon />
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Email Address</span>
              <span className="text-slate-900 text-base grow">{auth.email}</span>
            </div>
            <button className="btn-secondary w-[162px] !cursor-default invisible">Edit Password</button>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <LockIcon />
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Password</span>
              <span className="text-slate-900 text-base grow">••••••••••••••••</span>
            </div>
            <button className="btn-secondary w-[162px]" onClick={onEditPassword}>Edit Password</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
