import adminApi from "api/admin"
import { ICraigV2Config, ICraigV2Release, ICraigV2TopLevelDialog } from "api/types"
import {
  Dispatch,
  FC, PropsWithChildren, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from "react"

export type CraigV2ConfigContextType = {
    config: ICraigV2Config | undefined;
    setConfig: Dispatch<SetStateAction<ICraigV2Config | undefined>>;
    releases: {
        live?: ICraigV2Release<"live">;
        dev?: ICraigV2Release<"dev">;
    }
    setReleases: Dispatch<SetStateAction<{
        live?: ICraigV2Release<"live">;
        dev?: ICraigV2Release<"dev">;
    }>>;
    updateConfig: () => void;
    release: (channel: "live" | "dev") => void;
    setDialog: (index: number) => Dispatch<SetStateAction<ICraigV2TopLevelDialog>>;
    submitting: boolean;
    importDialogsFromFile: () => void;
    exportConfig: () => void;
    cost?: {
        cost: number;
        tokens: number;
    };
}

const context = createContext<CraigV2ConfigContextType>({} as CraigV2ConfigContextType)

export const useCraigV2Config = () => useContext(context)

const CraigV2ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const [config, setConfig] = useState<ICraigV2Config>()
  const [cost, setCost] = useState<{
        cost: number;
        tokens: number;
  }>()
  const [releases, setReleases] = useState<{
        live?: ICraigV2Release<"live">;
        dev?: ICraigV2Release<"dev">;
    }>({})
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    adminApi.getCraigV2Config().then(setConfig)
    adminApi.getReleases().then(setReleases)
    adminApi.getReleaseCosts().then(res => setCost(res.estimate))
  }, [])

  const updateConfig = useCallback(() => {
    if (!config || submitting) return
    setSubmitting(true)
    adminApi.updateCraigV2Config(config).then(res => {
      setConfig(res)
      adminApi.getReleaseCosts().then(res => setCost(res.estimate))
    }).finally(() => setSubmitting(false))
  }, [config, submitting])

  const release = useCallback((channel: "live" | "dev") => {
    if (submitting) return
    const confirm = window.confirm(`Are you sure you want to release the ${channel} channel?`)
    if (!confirm) return
    setSubmitting(true)
    adminApi.releaseCraigV2(channel).then(res => setReleases(prev => ({ ...prev, [channel]: res }))).finally(() => setSubmitting(false))
  }, [submitting])

  const setDialog = useCallback((index: number) => {
    if (submitting) return () => {}

    return (update: ICraigV2TopLevelDialog | ((old: ICraigV2TopLevelDialog) => ICraigV2TopLevelDialog)) => setConfig(old => {
      if (!old) return old

      return {
        ...old,
        dialogs: old.dialogs.map((dialog, i) => {
          if (i === index) {
            if (typeof update === "function") return update(dialog as ICraigV2TopLevelDialog)

            return update
          }

          return dialog
        }),
      }
    })
  }, [submitting])

  const importDialogsFromFile = useCallback(() => {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json"
    input.onchange = async() => {
      if (!input.files || !input.files.length) return
      const file = input.files[0]
      const content = await file.text()
      const { dialogs, instructions } = JSON.parse(content) as ICraigV2Config
      setConfig(old => {
        if (!old) return old

        const addedDialogNames = dialogs.map(dialog => dialog.name)

        return {
          ...old,
          dialogs: [...old.dialogs.filter(d => !addedDialogNames.includes(d.name)), ...dialogs],
        }
      })
    }
    input.click()
  }, [])

  const exportConfig = useCallback(() => {
    if (!config) return
    const content = JSON.stringify(config, null, 2)
    const blob = new Blob([content], { type: "application/json" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = "craig_v2_config.json"
    a.click()
  }, [config])

  return (
    <context.Provider
      value={{
        config,
        setConfig,
        releases,
        setReleases,
        updateConfig,
        release,
        submitting,
        setDialog,
        importDialogsFromFile,
        exportConfig,
        cost,
      }}
    >
      {children}
    </context.Provider>
  )
}

export default CraigV2ConfigProvider
