import useAwaitableModal from "hooks/useAwaitableModal"
import { FC, useState } from "react"
import ReleasesModal from "./components/ReleasesModal"
import { useCraigV2Config } from "contexts/CraigV2Config"
import Loader from "components/loaders/Loader"
import Dialog from "./components/Dialog"
import CraigChat from "./components/Chat"

const CraigDialogConfigPage: FC = () => {
  const [showReleases, ReleasesComponent] = useAwaitableModal(ReleasesModal, {})
  const { releases, config, submitting, updateConfig, setConfig, setDialog, importDialogsFromFile, exportConfig, cost } = useCraigV2Config()
  const [openIndex, setOpenIndex] = useState<number | null>(null)
  const [search, setSearch] = useState("")
  const loading = !releases || !config

  const toggleDialog = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  const addDialog = () => {
    setConfig(old => {
      if (!old) return old
      setOpenIndex(old.dialogs.length)

      return {
        ...old,
        dialogs: [...old.dialogs, {
          contexts: {},
          followUps: [],
          userMessageExamples: [],
          craigResponseExamples: [],
          name: "New Dialog " + old.dialogs.length,
          messages: [],
        }],
      }
    })
  }

  const onDeleteDialog = (index: number) => {
    setConfig(old => old && ({
      ...old,
      dialogs: old.dialogs.filter((_, i) => i !== index),
    }))
  }

  if (loading) return <div className="w-full h-full flex items-center justify-center"><Loader /></div>

  return (
    <div className="w-full h-full relative overflow-hidden flex flex-col">
      {ReleasesComponent}
      {submitting && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-2.5 z-50">
          <Loader />
        </div>
      )}
      <div className="flex items-center p-5 border-b border-b-slate-100 gap-4">
        <span className="text-xl font-semibold text-primary-blue">
          Craig Dialogs Config
        </span>
        <button className="btn btn-secondary" onClick={() => showReleases().catch(() => null)}>
          Releases
        </button>
        <input
          type="search"
          placeholder="Search Dialogs"
          value={search}
          onChange={e => setSearch(e.target.value)}
          className="input p-2"
        />
        <div className="grow" />
        <button className="btn btn-secondary" onClick={() => importDialogsFromFile()}>
          Import From File
        </button>
        <button className="btn btn-secondary" onClick={() => exportConfig()}>
          Export Config
        </button>
        <button className="btn btn-primary" disabled={submitting} onClick={() => updateConfig()}>Save</button>
      </div>
      <div className="w-full grow flex overflow-hidden">
        <div className="grow flex overflow-y-auto">
          <div className="flex flex-col items-start gap-4 py-3 px-5 grow">
            <div className="p-5 border-b border-b-slate-100 w-full">
              <span className="font-semibold text-lg text-primary-blue">Instructions</span>
              <textarea
                defaultValue={config.instructions}
                onBlur={e => setConfig(old => old && ({ ...old, instructions: e.target.value }))}
                className="input w-full h-32 p-2 mt-2"
                placeholder="Instructions for the assistant"
              />
            </div>
            {config.dialogs.length
              ? config.dialogs.map((dialog, i) => (
                dialog.name.toLowerCase().includes(search.toLowerCase())
              && <Dialog
                key={dialog.name}
                dialog={dialog}
                open={openIndex === i}
                toggleOpen={() => toggleDialog(i)}
                onDelete={() => onDeleteDialog(i)}
                setDialog={setDialog(i)}
              />
              )) : (
                <div className="flex items-center justify-center w-full h-full">
                  <span className="text-lg text-text-secondary">No Dialogs Found</span>
                </div>
              )}
            <div className="p-5 border-t border-t-slate-100 w-full">
              <button onClick={addDialog} className="btn-primary">
                Add New Dialog
              </button>
            </div>
          </div>
        </div>
        <div className="max-w-[24rem] w-[24rem] h-full shrink-0 grow-0">
          <CraigChat />
        </div>
      </div>
    </div>
  )
}

export default CraigDialogConfigPage
