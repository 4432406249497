import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from "firebase/auth"
import { handleError } from "helpers/errors"

class Api {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async authEmail(email: string, password: string) {
    return signInWithEmailAndPassword(firebase.auth, email, password)
  }

  async updateEmail(email: string) {
    return this.api.patch("/user/email", { email }).then(res => signInWithCustomToken(firebase.auth, res.data))
  }

  async updatePassword(oldPass: string, password: string) {
    if (!firebase.auth.currentUser) {
      handleError("You are not logged in")
      throw new Error()
    }
    try {
      await reauthenticateWithCredential(
        firebase.auth.currentUser,
        EmailAuthProvider.credential(firebase.auth.currentUser.email as string, oldPass),
      )
    } catch (err: any) {
      if (err.code === "auth/wrong-password") {
        handleError("Your old password is incorrect")
      }
      throw err
    }

    return this.api.patch("/user/password", { password }).then(res => signInWithCustomToken(firebase.auth, res.data))
  }
}

const api = new Api()

export default api
