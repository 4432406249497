import Modal from "components/Modal"
import Tooltip from "components/Tooltip"
import { useCraigV2Config } from "contexts/CraigV2Config"
import { AwaitableModal } from "hooks/useAwaitableModal"

const ReleasesModal: AwaitableModal = ({ resolve, open }) => {
  const { releases, submitting, release } = useCraigV2Config()
  const liveReleased = !!releases.live
  const devReleased = !!releases.dev

  return (
    <Modal open={open} close={resolve}>
      <div className="bg-white rounded-2.5 w-[32rem]">
        <header className="p-5 border-b border-b-slate-200">
          <span className="font-medium text-lg">Release Channels</span>
        </header>
        <main className="w-full py-3">
          <table className="w-full">
            <thead>
              <tr>
                <th>Channel</th>
                <th>Version</th>
                <th>Latest Release At</th>
                <th />
              </tr>
            </thead>
            <tbody className="[&_td]:text-center">
              <tr>
                <td className="pl-5">Dev</td>
                {devReleased ? (
                  <>
                    <td>{releases.dev?.version}</td>
                    <td>{new Date(releases.dev?.updatedAt!).toLocaleString()}</td>
                  </>
                ) : (
                  <td className="text-text-secondary text-center" colSpan={2}>No releases available</td>
                )}
                <td>
                  <Tooltip text="Release Changes" position="top" className="z-[1000000000]">
                    <button className="btn-secondary" onClick={() => release("dev")} disabled={submitting}>Release</button>
                  </Tooltip>
                </td>
              </tr>
              <tr>
                <td className="pl-5">Live</td>
                {liveReleased ? (
                  <>
                    <td>{releases.live?.version}</td>
                    <td>{new Date(releases.live?.updatedAt!).toLocaleString()}</td>
                  </>
                ) : (
                  <td className="text-text-secondary text-center" colSpan={2}>No releases available</td>
                )}
                <td>
                  <Tooltip text={devReleased ? "Release Dev" : "Dev must be released first"} position="top" className="z-[1000000000]">
                    <button className="btn-secondary" onClick={() => release("live")} disabled={!devReleased || submitting}>Release</button>
                  </Tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </main>
        <footer className="p-5 border-t border-t-slate-200 flex gap-4 justify-center">
          <button className="btn-secondary" onClick={() => resolve()}>Close</button>
        </footer>
      </div>
    </Modal>
  )
}

export default ReleasesModal
