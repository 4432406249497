import FullLoader from "components/loaders/FullLoader"
import useAuthContext from "contexts/Auth/useAuthContext"
import { FC } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import AuthRouter from "routes/auth"
import PrivateRouter from "routes/private"

const Root: FC = () => {
  const auth = useAuthContext()

  if (auth === undefined) {
    return <FullLoader />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={!auth ? <AuthRouter /> : <PrivateRouter />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Root
