import useCraig from "contexts/Craig/useCraig"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import UserBubble from "./components/UserBubble"
import CraigBubble from "./components/CraigBubble"

const CraigChat: FC = ({}) => {
  const [text, setText] = useState("")
  const { messages, sendMessage, sending, environment, setEnvironment, location, setLocation } = useCraig()
  const chatContainer = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (!chatContainer.current) return
    chatContainer.current.scrollTop = chatContainer.current.scrollHeight
  }

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    text && sendMessage(text, () => {
      setText("")
    })

    return false
  }, [sendMessage, text])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <div className="bg-white w-full h-full flex flex-col">
      <div className="flex items-center justify-between w-full p-4 border-b border-slate-100">
        <div className="flex items-center gap-2">
          <span className="text-sm font-semibold text-slate-900 w-max">Chat with Craig</span>
          <input type="text" placeholder="URL in gym" value={location} onChange={e => setLocation(e.currentTarget.value)} />
        </div>
        {/*<select
          value={environment}
          onChange={e => setEnvironment(e.currentTarget.value as any)}
          className="text-sm font-medium text-slate-950 bg-transparent cursor-pointer"
        >
          <option value="live">Live</option>
          <option value="dev">Dev</option>
  </select>*/}
      </div>
      <div ref={chatContainer} className="grow flex flex-col w-full gap-8 p-4 pb-8 overflow-y-scroll scroll-behavior-auto">
        {messages?.map(message => (
          message.user ? <UserBubble key={message.id} message={message} /> : <CraigBubble key={message.id} message={message} />
        ))}
      </div>
      <form onSubmit={handleSubmit} className="flex bg-slate-950 rounded-t-2xl" style={{ pointerEvents: sending ? "none" : "auto" }}>
        <input
          value={sending ? "..." : text}
          readOnly={sending}
          onChange={e => setText(e.currentTarget.value)}
          className="h-full p-8 grow bg-transparent outline-none text-white"
        />
        <button
          type="submit"
          disabled={sending}
          className="h-full p-8 bg-slate-900 rounded-r-2xl text-white"
        >
          Send
        </button>
      </form>
    </div>
  )
}

export default CraigChat
