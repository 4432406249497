import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import { ICraigUnmatchedFallbackConfig, ICraigV2Config, ICraigV2Release } from "./types"

class AdminApi {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async getReleases() {
    return this.api.get("/craigv2/admin/releases").then(res => res.data as {
      live: ICraigV2Release<"live">,
      dev: ICraigV2Release<"dev">,
    })
  }

  async getCraigV2Config() {
    return this.api.get("/craigv2/admin/config").then(res => res.data as ICraigV2Config)
  }

  async updateCraigV2Config(config: ICraigV2Config) {
    return this.api.patch("/craigv2/admin/config", { config }).then(res => res.data as ICraigV2Config)
  }

  async getReleaseCosts() {
    return this.api.get("/craigv2/admin/release/cost").then(res => res.data as { estimate: {
      cost: number,
      tokens: number,
    } })
  }

  async releaseCraigV2<Channel extends "live" | "dev" = "live" | "dev">(channel: Channel) {
    return this.api.post("/craigv2/admin/release/"+channel).then(res => res.data as ICraigV2Release<Channel>)
  }

  async getUserByEmail(email: string) {
    return this.api.get("/admin/users/email/?email="+email).then(res => res.data as {id: string}).catch(() => null)
  }

  async getUnmatchedFallbackConfig() {
    return this.api.get("/craigv2/admin/catcher").then(res => res.data as ICraigUnmatchedFallbackConfig)
  }

  async updateUnmatchedFallbackConfig(config: ICraigUnmatchedFallbackConfig) {
    return this.api.patch("/craigv2/admin/catcher", { config }).then(res => res.data as ICraigUnmatchedFallbackConfig)
  }

  async releaseUnmatchedFallbackConfig() {
    return this.api.post("/craigv2/admin/catcher/release").then(res => res.data as string)
  }

  async pollRelease(version: string) {
    return this.api.get("/craigv2/admin/catcher/release?version="+version).then(res => res.data as {finished: boolean})
  }

  async getFallbackConversations(page = 1) {
    return this.api.get("/craigv2/admin/catcher/conversations?page="+page).then(res => res.data as {conversations: {
    id: string;
    user: string;
    createdAt: string;
    context: string;
    type: "1" | "2" | "3" | "4";
    message?: string;
    }[],
    hasMore: boolean
  })

  }
}

const adminApi = new AdminApi()

export default adminApi
