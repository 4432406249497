import { ICraigMessage } from "api/types"
import { createContext } from "react"

interface ICraigContext {
    messages?: ICraigMessage[]
    sending: boolean
    sendMessage: (message: string, onSuccess?: () => void) => void,
    environment: "live" | "dev"
    setEnvironment: (env: "live" | "dev") => void
    location: string
    setLocation: (location: string) => void
}

const CraigContext = createContext<ICraigContext>(undefined as any)

export default CraigContext
