import { FC, useCallback, useEffect, useState } from "react"
import Loader from "components/loaders/Loader"
import { ICraigUnmatchedFallbackConfig } from "api/types"
import adminApi from "api/admin"

const CraigUnmatchedFallbacksPage: FC = () => {
  const [config, setConfig] = useState<ICraigUnmatchedFallbackConfig>()
  const [submitting, setSubmitting] = useState(false)
  const [releaseStatus, setReleaseStatus] = useState<"RELEASING" | "TRAINING" | null>(null)

  const addMetaMessage = useCallback(() => {
    setConfig(old => {
      if (!old) return old

      return {
        ...old,
        metaQnAExamples: [...old.metaQnAExamples, { question: "", answer: "" }],
      }
    })
  }, [])

  const deleteMetaMessage = useCallback((index: number) => {
    setConfig(old => old && ({
      ...old,
      metaQnAExamples: old.metaQnAExamples.filter((_, i) => i !== index),
    }))
  }, [])

  useEffect(() => {
    adminApi.getUnmatchedFallbackConfig().then(setConfig)
  }, [])

  const save = useCallback(() => {
    if (!config) return
    if (submitting) return
    setSubmitting(true)
    adminApi.updateUnmatchedFallbackConfig(config).finally(() => setSubmitting(false))
  }, [config, submitting])

  const release = useCallback(() => {
    if (releaseStatus) return
    const confirmed = window.confirm("Are you sure you want to release the unmatched fallback config?")
    if (!confirmed) return
    setReleaseStatus("RELEASING")
    adminApi.releaseUnmatchedFallbackConfig()
      .then(version => {
        setReleaseStatus("TRAINING")
        const poll: () => Promise<void> = async() => {
          const res = await adminApi.pollRelease(version)
          if (res.finished) {
            setConfig(old => old && ({ ...old, currentVersion: version }))
          } else {
            await new Promise(r => setTimeout(r, 1000))

            return poll()
          }
        }

        return poll()
      })
      .finally(() => setReleaseStatus(null))
  }, [releaseStatus])

  if (!config) return <div className="w-full h-full flex items-center justify-center"><Loader /></div>

  return (
    <div className="w-full h-full relative overflow-hidden flex flex-col">
      {submitting && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-2.5 z-50">
          <Loader />
        </div>
      )}
      <div className="flex items-center p-5 border-b border-b-slate-100 gap-4">
        <span className="text-xl font-semibold text-primary-blue">
          Craig Dialogs Config
        </span>
        <button className="btn btn-secondary" disabled={submitting || !!releaseStatus} onClick={release}>
          {releaseStatus === null ? "Release" : releaseStatus}
        </button>
        <div className="grow" />
        <button className="btn btn-primary" disabled={submitting || !!releaseStatus} onClick={save}>Save</button>
      </div>
      <div className="w-full grow flex overflow-hidden">
        <div className="grow flex overflow-y-auto w-full">
          <div className="flex flex-col items-start gap-4 py-3 px-5 grow w-full">
            <span className="text-lg font-semibold text-primary-blue">Sample Company Info Doc</span>
            <div className="flex flex-col gap-2 border-t border-t-slate-200 w-full">
              <input
                value={config.knowledgeBaseDocument.url}
                placeholder="Knowledge Base Document URL"
                onBlur={e => setConfig(old => old && ({
                  ...old,
                  knowledgeBaseDocument: {
                    ...old.knowledgeBaseDocument,
                    url: e.target.value,
                  },
                }))}
                className="input p-2 w-full border border-slate-200 outline-none"
              />
            </div>
            <div className="flex items-center gap-4 border-b border-b-slate-200 w-full pb-4">
              <span className="text-lg font-semibold text-primary-blue">Meta Message Training Examples</span>
              <button className="btn btn-primary" onClick={addMetaMessage}>Add Meta Message</button>
            </div>
            <div className="flex flex-col gap-2 w-full">
              {config.metaQnAExamples.map((example, index) => (
                <div key={index + example.answer + example.question} className="flex items-center gap-4 w-full">
                  <input
                    defaultValue={example.question}
                    placeholder="Accountant's Question"
                    onBlur={e => setConfig(old => old && ({
                      ...old,
                      metaQnAExamples: old.metaQnAExamples.map((ex, i) => i === index ? { ...ex, question: e.target.value } : ex),
                    }))}
                    className="input p-2 grow border border-slate-200 outline-none"
                  />
                  <input
                    defaultValue={example.answer}
                    placeholder="Craig's Answer"
                    onBlur={e => setConfig(old => old && ({
                      ...old,
                      metaQnAExamples: old.metaQnAExamples.map((ex, i) => i === index ? { ...ex, answer: e.target.value } : ex),
                    }))}
                    className="input p-2 grow border border-slate-200 outline-none"
                  />
                  <button className="btn btn-destroy" onClick={() => deleteMetaMessage(index)}>Delete</button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CraigUnmatchedFallbacksPage
